import React, { useRef } from 'react';
import Reward from 'react-rewards';
import { func, number, object } from 'prop-types';

import './CookieClicker.css';

function CookieClicker(props) {
  const { count, onClick, rewardConfig } = props;
  const rewardRef = useRef();
  const config = {
    emoji: ['🍪'],
    elementCount: count,
    ...rewardConfig
  };
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }

    rewardRef.current.rewardMe();
  };

  return (
    <Reward ref={rewardRef} type="emoji" config={config}>
      <button className="cc-button" onClick={handleClick}>
        🍪
      </button>
    </Reward>
  );
}

CookieClicker.propTypes = {
  count: number,
  onClick: func,
  rewardConfig: object
};

CookieClicker.defaultProps = {
  count: 20,
  rewardConfig: {
    elementSize: 25,
    spread: 200,
    startVelocity: 30
  }
};

export default CookieClicker;
