import { useState } from 'react';
import ReactCookieClicker from 'react-cookie-clicker';

function CookieClicker() {
  const [count, setCount] = useState(0);
  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <>
      <ReactCookieClicker count={count + 1} onClick={handleClick} />
      <h2 className="cc-app-title">Click the cookie</h2>
      <p className="cc-app-text">Count: {count}</p>
    </>
  )
}

export default CookieClicker;