import './App.css';
import { RedirectToSignIn, SignedIn, SignedOut, UserButton } from '@clerk/clerk-react';
import CookieClicker from './CookieClicker';

function App() {
  return (
    <div className="cc-app">
      <header className="cc-app-header">
        <nav className="cc-app-nav">
          <h1 className="cc-app-logo">C🍪🍪kie Clicker</h1>
          <UserButton />
        </nav>
      </header>
      <SignedIn>
        <main className="cc-app-main">
          <CookieClicker />
        </main>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </div>
  );
}

export default App;
